<template>
    <div>
        <div
            v-for="(selection, index) in selections"
            :key="index"
            class="single-campaign-wrapper">
            <div class="single-campaign multiple">
                <div class="icon">
                    <icon
                        :name="selection.icon"
                        color="#8F9EA6"
                        size="25" />
                </div>
                <div class="single-campaign__main">
                    <span class="single-campaign__title">CAMPAIGN</span>
                    <span class="single-campaign__name">{{ selection.campaign }}</span>
                </div>
                <div class="single-campaign__bottom">
                    <span class="single-campaign__title">AD SET</span>
                    <span class="single-campaign__name">{{ selection.adSet }}</span>
                </div>
            </div>
            <a
                class="sad-edit"
                href="#"
                @click.prevent="handleEdit">
                <span>edit</span>
            </a>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { getChannelByPlatformName } from '@/components/onboarding/channel-setup/channels';
import { mapState } from 'vuex';

export default {
    components: {
        Icon
    },
    computed: {
        ...mapState({
            selectedChannels: (state) => state.adDeployment.platforms,
            campaignName: (state) => state.adDeployment.campaign_selection.all_platforms.campaign_name,
            campaignSelection: (state) => state.adDeployment.campaign_selection,
            specialAdsCategory: (state) => state.adDeployment.campaign_selection.all_platforms.ad_category,
        }),
        allPlatformsSelected() {
            return this.campaignSelection.selection === 'all_platforms';
        },
        selections() {
            if (this.allPlatformsSelected) {
                return [];
            }

            return this.selectedChannels.map(channel => {
                const { icon_name: icon } = getChannelByPlatformName(channel);
                return {
                    icon,
                    campaign: this.campaignSelection.platform_specific[channel].campaign?.name,
                    adSet: this.campaignSelection.platform_specific[channel].adSet?.name,
                };
            });
        },
    },
    methods: {
        handleEdit() {
            this.selectedChannels.forEach(platform => {
                this.$store.commit(
                    'adDeployment/setExistingPlatformCampaignOrAdSet',
                    {
                        value: null,
                        platform,
                        level: 'campaign'
                    }
                );
                this.$store.commit(
                    'adDeployment/setExistingPlatformCampaignOrAdSet',
                    {
                        value: null,
                        platform,
                        level: 'adSet'
                    }
                );
            });

            this.$store.dispatch(
                'adDeployment/goToStepByName',
                'ad_destination'
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.single-campaign-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.single-campaign {
    max-width: calc(100% - 100px);
    display: inline-block;
    background: white;
    padding: 10px 45px 10px 15px;
    border: 1px solid #DFDFE0;

    &__bottom {
        margin-top: 12px;
        position: relative;
        padding-left: 30px;

        &:after {
            content: '';
            position: absolute;
            width: 20px;
            height: 1px;
            background: #999999;
            top: 10px;
            left: 5px;
        }

        &:before {
            content: '';
            position: absolute;
            width: 1px;
            height: 15px;
            background: #999999;
            top: -5px;
            left: 5px;
        }
    }

    &__title {
        font-weight: 700;
        margin-right: 15px;
    }

    &__name {
        color: #03A2EA;
        font-style: italic;
    }

    &.multiple {
        position: relative;
        padding-left: 60px;

        .icon {
            position: absolute;
            top: 10px;
            left: 15px;
        }
    }
}
</style>