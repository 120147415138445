<template>
    <div
        v-intersect="onIntersect"
        class="existing-field container-object">
        <div class="container-autocomplete">
            <v-autocomplete
                :items="objectsToUse"
                :item-disabled="itemDisabled"
                :return-object="true"
                :loading="loading"
                item-text="name"
                item-value="id"
                :label="getLabel"
                :error-messages="errorMessage"
                required
                class="styled-field"
                :filter="filterObject"
                :search-input.sync="search"
                @change="handleObjectSelection">
                <template #item="{item}">
                    <div
                        v-if="item.lazyLoading"
                        v-intersect="onNextPage">
                        ...Loading
                    </div>
                    <div v-else>
                        <div>
                            <div v-if="item.validationErrors.length === 0">
                                <div class="option">
                                    <div class="status">
                                        <div
                                            :class="{
                                                'available': item.status === activeStatus,
                                                'not-available': item.status === pausedStatus
                                            }" />
                                    </div>
                                    <div class="option-text">
                                        {{ item.name }}
                                    </div>
                                </div>
                                <div
                                    v-if="item.info_messages.length > 0"
                                    class="sub-text ad-set-error-message">
                                    {{ item.info_messages[0] }}
                                </div>
                            </div>

                            <div
                                v-if="item.validationErrors.length > 0"
                                @click.stop.prevent>
                                <div class="option">
                                    <div class="status">
                                        <div
                                            :class="{
                                                'available': item.status === activeStatus,
                                                'not-available': item.status === pausedStatus
                                            }" />
                                    </div>
                                    <div class="option-text-disabled">
                                        {{ item.name }}
                                    </div>
                                </div>
                                <div
                                    class="sub-text">
                                    <div
                                        v-if="item.validationErrors.length > 0"
                                        class="ad-set-error-message">
                                        <!-- show the first error message -->
                                        {{ item.validationErrors[0].errorMessage }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template #selection="{ item }">
                    <div
                        class="option">
                        <div class="status">
                            <div
                                :class="{
                                    'available': item.status === activeStatus,
                                    'not-available': item.status === pausedStatus
                                }" />
                        </div>
                        <div>
                            {{ trimSelection(item.name) }}
                        </div>
                    </div>
                </template>
            </v-autocomplete>
        </div>
        <div class="switch-container">
            <span
                v-if="isCampaignComponent"
                class="enable-text">DISABLE/ENABLE</span>
            <v-switch
                class="switcher ml-2"
                color="primary"
                :true-value="activeStatus"
                :false-value="pausedStatus"
                :disabled="disableSwitcher"
                :input-value="objectStatus"
                @change="switchObject" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { debounce } from 'lodash';
import { isBefore } from 'date-fns';
import { FACEBOOK_BB_PLATFORM_ID } from '@/helpers/globals.js';
import { BB_ADVANTAGE_PLUS_OBJECTIVES } from '../../store/constants';
import { DEPLOYMENT_PLATFORMS } from '@/components/ad-deployment/store/constants.js';

export default {
    name: 'AutoCompleteSelector',
    props: {
        // Could be either AdSet or Campaign
        objectType: {
            type: String,
            required: true
        },
        platform: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            objects: [],
            objectsSearch: [],
            loading: false,
            errorMessage: '',
            errorStatus: false,
            nextPage: '',
            lazyLoadingItem: {
                lazyLoading: true,
            },
            activeStatus: 'ACTIVE',
            pausedStatus: 'PAUSED',
            search: null,
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            adDeploymentAds: (state) => state.adDeployment.ads,
        }),
        objectStatus() {
            return this.isCampaignComponent
                ? this.campaignInStore?.status
                : this.adSetInStore?.status;
        },
        objectId() {
            return this.isCampaignComponent
                ? this.campaignInStore?.id
                : this.adSetInStore?.id;
        },
        isCampaignComponent() {
            return this.objectType === 'campaign';
        },
        getLabel() {
            return this.isCampaignComponent ? 'CAMPAIGN' : 'AD SET';
        },
        anAdHasAdvantagePlusOn() {
            return this.adDeploymentAds.some((ad) => ad.configuration.creative_enhancement);
        },
        disableSwitcher() {
            return this.isCampaignComponent
                ? !this.campaignInStore?.id
                : !this.adSetInStore?.id;
        },
        campaignInStore: {
            get() {
                return this.$store.state.adDeployment.campaign_selection.platform_specific[this.platform].campaign;
            },
            set(value) {
                this.$store.commit(
                    'adDeployment/setExistingPlatformCampaignOrAdSet',
                    {
                        value,
                        platform: this.platform,
                        level: 'campaign'
                    }
                );
            }
        },
        adSetInStore: {
            get() {
                return this.$store.state.adDeployment.campaign_selection.platform_specific[this.platform].adSet;
            },
            set(value) {
                this.$store.commit(
                    'adDeployment/setExistingPlatformCampaignOrAdSet',
                    {
                        value,
                        platform: this.platform,
                        level: 'adSet'
                    }
                );
            }
        },
        isFacebook() {
            return this.platform === 'facebook';
        },
        objectsToUse() {
            if (this.objectsSearch.length) {
                return this.objectsSearch;
            }

            return this.objects;
        }
    },
    watch: {
        campaignInStore: {
            handler(newCampaign, oldCampaign) {
                if (!this.isCampaignComponent && newCampaign?.id !== oldCampaign?.id) {
                    this.getObjectsFromApi(true);
                }
            },
        },
        search: debounce(async function(value) {
            if (value?.length === 0) {
                this.objectsSearch = [];
                return;
            }

            this.loadingSwitch = true;

            const payload = {
                dealerId: this.currentDealer.id,
                platform: this.platform,
                params: {
                    stats: false,
                    search: value,
                }
            };

            const response = this.isCampaignComponent
                ? await this.$apiRepository.getDealerPlatformCampaigns(payload)
                : await this.$apiRepository.getInternalDealerPlatformAdsSetPreview(payload);


            this.objectsSearch = this.handleResponse(this.objectsSearch, response, true);
            this.loadingSwitch = false;
        }, 500),
    },
    methods: {
        isDateValid(date) {
            const today = new Date();
            if (date && isBefore(date, today)) {
                return false;
            }
            return true;
        },
        filterObject(item, queryText) {
            return (
                item.name?.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
                -1
            );
        },
        trimSelection(selection) {
            const sliceSize = this.isCampaignComponent ? 50 : 40;
            const elipses = selection.length < sliceSize ? '' : '...';
            return `${selection.slice(0, sliceSize)}${elipses}`;
        },
        async updateCampaign() {
            try {
                const payload = {
                    dealerId: this.currentDealer.id,
                    platform: this.platform,
                    campaignId: this.campaignInStore.id,
                    params: {
                        status: this.campaignInStore.status === this.activeStatus ? this.pausedStatus : this.activeStatus
                    }
                };

                this.loadingSwitch = true;
                await this.$apiRepository.updateDealerPlatformCampaign(payload);
            } catch (err) {
                console.log(err);
            } finally {
                this.loadingSwitch = false;
            }
        },
        async switchObject() {
            try {
                this.loadingSwitch = true;
                const objectType = this.isCampaignComponent ? 'campaign' : 'adSet';

                const payload = {
                    dealerId: this.currentDealer.id,
                    platform: this.platform,
                    [`${objectType}Id`]: this.objectId,
                    params: {
                        status: this.objectStatus === this.activeStatus ? this.pausedStatus : this.activeStatus
                    }
                };

                const response = this.isCampaignComponent
                    ? await this.$apiRepository.updateDealerPlatformCampaign(payload)
                    : await this.$apiRepository.updateDealerPlatformAdSet(payload);

                const index = this.objects.findIndex(object => object.id = response.data.external_id);
                this.objects[index].status = response.data.status;

                this.$store.commit(
                    'adDeployment/setStatusForAdSetOrCampaign',
                    {
                        objectType,
                        platform: this.platform,
                        status: response.data.status
                    }
                );
            } catch (err) {
                console.log(err);
            } finally {
                this.loadingSwitch = false;
            }
        },
        async handleObjectSelection(selectedObject) {
            if (this.isCampaignComponent) {
                this.handleCampaignSelection(selectedObject);
            } else {
                await this.handleAdSetSelection(selectedObject);
            }
        },
        handleCampaignSelection(selectedObject) {
            this.adSetInStore = null;
            this.campaignInStore = selectedObject;
        },
        async handleAdSetSelection(selectedObject) {
            try {
                const params = {
                    stats: false,
                    page_size: 100,
                    campaignIds: [this.campaignInStore.id]
                };

                const response = await this.$apiRepository.getDealerPlatformAdSets({
                    dealerId: this.currentDealer.id,
                    platform: this.platform,
                    params
                });

                const numberOfAds = response.data.data.length;

                const platformMaxNumberOfAdsInAdSet = DEPLOYMENT_PLATFORMS.find(platform => platform.name === this.platform)?.maxNumberOfAdsInAdSet;

                if (numberOfAds >= platformMaxNumberOfAdsInAdSet) {
                    this.adSetMessage = '';
                    this.errorMessage = 'You can not use this Ad Set as its at the maximum number of Ads';
                } else {
                    this.errorMessage = '';
                    this.adSetInStore = selectedObject;
                }
            } catch (err) {
                console.log(err);
            }
        },
        itemDisabled(item) {
            if (!this.isDateValid(item.end_date)) {
                return true;
            }

            if (!this.isCampaignComponent) {
                return item.lazyLoading || item.isDynamic;
            }

            return item.lazyLoading;
        },
        onNextPage() {
            if (this.nextPage) {
                this.getObjectsFromApi();
            }
        },
        onIntersect() {
            if (!this.hasIntersected && this.isCampaignComponent) {
                this.getObjectsFromApi();
            }

            this.hasIntersected = true;
        },
        usingAdvantagePlusObjective(objective) {
            // These are the objectives are the bb objective after being translated
            // from the platform objectives
            return !BB_ADVANTAGE_PLUS_OBJECTIVES.includes(objective?.toLowerCase());
        },
        handleResponse(array, response, cleanCachedObjects) {
            // Remove ...Loading if no next page exists
            if (array.length) {
                array.pop();
            }

            const responses = response?.data?.data || response.data;

            const newEntries = responses.map(object => {
                const pageMatches = object?.platform_id === FACEBOOK_BB_PLATFORM_ID ? object?.page_matches_promoted_object : true;
                return {
                    id: object.external_id,
                    name: object.name,
                    status: object.status,
                    end_date: object.budget?.end_date,
                    objective: object.objective,
                    info_messages: [
                        (this.isCampaignComponent &&
                            object?.platform_id === FACEBOOK_BB_PLATFORM_ID &&
                            this.anAdHasAdvantagePlusOn &&
                            this.usingAdvantagePlusObjective(object.objective)
                        ) ? 'This campaign objective does not support Advantage+ Creative Standard Enhancements' : null,
                    ].filter(x => x),
                    validationErrors: [
                        // Campaign validation Errors
                        ...(this.isCampaignComponent ? [
                            {
                                name: 'End Date is past',
                                errorMessage: 'The Ad Set has passed its scheduled end date',
                                valid: this.isDateValid(object.budget?.end_date)
                            },
                            {
                                name: 'Lead objective',
                                errorMessage: 'This campaign is using a lead generation objective which is not supported',
                                valid: object?.objective !== 'Lead Generation'
                            },
                            {
                                name: 'Non-ODAX objectives',
                                errorMessage: 'Meta does not allow new ads to be added to Campaigns with non-ODAX objectives',
                                valid: ![
                                    'App Installs',
                                    'Brand Awareness',
                                    'Conversions',
                                    'Event Responses',
                                    'Link Clicks',
                                    'Local Awareness',
                                    'Messages',
                                    'Offer Claims',
                                    'Page Likes',
                                    'Post Engagement',
                                    'Product Catalog Sales',
                                    'Reach',
                                    'Store Visits',
                                    'Video Views',
                                ].includes(object?.objective)
                            }
                        ] : []),
                        // Ad Set Validaion Errors
                        ...(!this.isCampaignComponent ? [
                            {
                                name: 'Dynamic',
                                errorMessage: 'The Ad Set is dynamic and cannot be used',
                                valid: !object?.is_dynamic
                            },
                            {
                                name: 'End Date is past',
                                errorMessage: 'The Ad Set has passed its scheduled end date',
                                valid: this.isDateValid(object.budget?.end_date)
                            },
                            {
                                name: 'Pages matching',
                                errorMessage: 'The Ad Set page does not match the page in our records',
                                valid: pageMatches
                            }
                        ] : [])
                    ].filter(validation => !validation.valid),
                };
            });

            if (!cleanCachedObjects) {
                array = [...array, ...newEntries || []];
            } else {
                array = newEntries;
            }

            // Push ...Loading on the list if there is a next page
            if (this.nextPage) {
                array.push(this.lazyLoadingItem);
            }

            return array;
        },
        async getObjectsFromApi(cleanCachedObjects = false) {
            if (!this.campaignInStore && !this.isCampaignComponent) {
                this.errorMessage = 'Please select a campaign first';
                return;
            }

            const payload = {
                dealerId: this.currentDealer.id,
                platform: this.platform,
                params: {
                    stats: false,
                    page_size: 25,
                    page: this.nextPage,
                    ...(!this.isCampaignComponent && { campaignIds: [this.campaignInStore.id] }),
                }
            };

            try {
                this.loading = true;
                this.errorMessage = '';

                const response = this.isCampaignComponent
                    ? await this.$apiRepository.getDealerPlatformCampaigns(payload)
                    : await this.$apiRepository.getInternalDealerPlatformAdsSetPreview(payload);

                this.nextPage = response?.data?.meta?.next_page;
                this.objects = this.handleResponse(this.objects, response, cleanCachedObjects);
            } catch (error) {
                this.errorStatus = true;
                this.errorMessage = `Error occurred while fetching ${this.objectType}s for ${this.platform}`;
            } finally {
                this.loading = false;
            }
        }
    }

};
</script>

<style lang="scss" scoped>
.ad-set-error-message {
    font-size: 12px;
    color: $gray-primary;
}

.option-text-disabled {
    color: #d3d3d3;
}

.container-object {
    display: flex;

    .container-autocomplete {
        width: 500px;
    }

    .switch-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 50px;
        width: 50px;

        .switcher {
            margin-top: 0px;
        }
    }
}

.enable-text {
    margin-top: -20px;
}

.status {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
        width: 15px;
        height: 15px;
        border-radius: 50%;
    }

    .available {
        background: #3EBF3F;
    }

    .not-available {
        background: #FF3A00;
    }
}

.option {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -10px;
}
</style>
