<template>
    <step-wrapper
        :index="index"
        :active="active"
        :valid="valid"
        :step="name"
        :title="$t('adDeployment.adDestination.title')"
        :help-link="helpLink"
        :description="$t('adDeployment.adDestination.description')">
        <styled-dialog v-model="dialogOpen">
            <div class="text-xs-center">
                <h3 class="mb-4">
                    Changing the ad destination after it has
                    been configured will reset all progress
                    after this step.
                </h3>
                <p>
                    Are you sure you want to continue?
                </p>
                <styled-button
                    class="mt-4"
                    @click="resetDeploymentLocation">
                    YES, CHANGE DESTINATION
                </styled-button>
            </div>
            <template #actions>
                <a
                    href="#"
                    class="red--text"
                    @click.prevent="dialogOpen = false">
                    Cancel
                </a>
            </template>
        </styled-dialog>
        <complete v-if="!active" />
        <template v-else>
            <div class="campaigns-title mb-2">
                Deployment Location
            </div>

            <div
                class="existing-campaign">
                <div
                    v-for="(platform, i) in selectedChannels"
                    :key="i">
                    <campaign-selector :platform="platform" />
                </div>
            </div>
            <styled-button
                small
                :disabled="disabledStatus"
                :loading="loadingCampaigns"
                @click="finishCurrentStep">
                Continue
            </styled-button>
        </template>
    </step-wrapper>
</template>

<script>
import StyledDialog from '@/components/globals/StyledDialog';
import StepWrapper from '../../components/StepWrapper';
import StyledButton from '@/components/globals/StyledButton';
import CampaignSelector from './CampaignSelector';
import Complete from './Complete';
import { requiredRules } from '@/helpers/validationRules';
import stepProps from '../stepProps';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'AdDestination',
    components: {
        StyledDialog,
        StepWrapper,
        StyledButton,
        CampaignSelector,
        Complete
    },
    props: stepProps,
    data() {
        return {
            dialogOpen: false,
            tempDeploymentLocation: null,
            requiredRules,
            specialAdCategories: [
                { name: 'Housing', value: 'housing' },
                { name: 'Credit', value: 'credit' },
                { name: 'Employment', value: 'employment' },
                { name: 'Issues Elections Politics', value: 'issues elections politics' },
                { name: 'None', value: 'none' }
            ],
            buttonDisabled: true,
            stepCompleted: false,
            campaignErrorMessage: '',
            campaignLoadingMessage: '',
            disableContinueButton: false,
            loadingCampaigns: false,
            campaignName: '',
            helpLink: 'https://support.buyerbridge.com/knowledge/how-to-ad-launcher#existing-campaign-selection'
        };
    },
    computed: {
        ...mapState({
            latestStep: state => state.adDeployment.latest_step,
            selectedChannels: state => state.adDeployment.platforms,
            campaign_selection: state => state.adDeployment.campaign_selection,
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        campaignNameInStore() {
            return this.$store.state.adDeployment.campaign_selection.all_platforms.campaign_name;
        },
        disabledStatus() {
            if (this.allPlatformsSelected && this.disableContinueButton) {
                return true;
            }
            if (!this.allPlatformsSelected && !this.valid) {
                return true;
            }
            return false;
        },
        specialAdsCategory: {
            get() {
                return this.$store.state.adDeployment.campaign_selection.all_platforms.ad_category;
            },
            set(value) {
                this.$store.commit('adDeployment/setSpecialAdsCategory', value);
            }
        },
        platformSpecificSelected() {
            return this.campaign_selection.selection === 'platform_specific';
        },
        allPlatformsSelected() {
            return this.campaign_selection.selection === 'all_platforms';
        }
    },
    methods: {
        ...mapActions({
            goToNextStep: 'adDeployment/goToNextStep'
        }),
        async finishCurrentStep() {
            if (this.allPlatformsSelected) {
                await this.checkIfCampaignAvailable();
                if (this.campaignErrorMessage === '') {
                    this.goToNextStep();
                }
            } else {
                this.goToNextStep();
            }
        },
        changeDeploymentLocation(value) {
            if(this.platformSpecificSelected) {
                this.disableContinueButton = false;
                this.campaignErrorMessage = '';
                this.campaignName = this.campaignNameInStore;
            }
            if (this.latestStep > this.index) {
                this.dialogOpen = true;
                this.tempDeploymentLocation = value;
            } else {
                // set new location only with reset to avoid conflicts in state
                this.resetDeploymentLocation(value);
            }
        },
        async resetDeploymentLocation(location) {
            // Reset the deployment configuration for this step
            // and all steps after it
            await this.$store.dispatch('adDeployment/resetExtendedState');

            // Alter the progress to restart at this step
            this.$store.dispatch(
                'adDeployment/alterProgressToStepByName',
                this.name
            );

            // Close the dialog and set the deployment location
            // to the user's original choice
            this.dialogOpen = false;
            const isLocationProvided = typeof location === 'string';
            const deploymentLocation = isLocationProvided ? location : this.tempDeploymentLocation;
            this.$store.commit(
                'adDeployment/setDeploymentLocation',
                deploymentLocation
            );
            this.tempDeploymentLocation = null;
        },

    },
};
</script>
<style lang="scss" scoped>
.campaigns-title{
    text-transform: uppercase;
    color: #7C8187;
    font-size: 12px;
}
.campaigns-selector{
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
}
.campaigns-select{
    display: inline-block;
    margin-left: 30px;
    margin-right: 30px;
    position: relative;
    cursor: pointer;
    &:after{
        content:'';
        position: absolute;
        width: 20px;
        height: 20px;
        background: white;
        border-radius: 50%;
        border: 2px solid #555C63;
        top: 0;
        right: calc(100% + 10px);
    }
    &.active{
        &:after{
            border: 2px solid #00A3EA;
        }
        &:before{
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            background: #00A3EA;
            border-radius: 50%;
            top: 5px;
            right: calc(100% + 15px);
            z-index: 2;
        }
    }
}
.campaign-name{
    max-width: 400px;
}
.campaign-category{
    max-width: 350px;
}
</style>