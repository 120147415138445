<template>
    <div class="existing-container">
        <div class="single-existing">
            <div class="existing-icon">
                <icon
                    :name="platform === 'facebook' ? 'meta' : platform"
                    color="#8F9EA6"
                    size="25" />
            </div>
            <div class="selector-container">
                <selector
                    :platform="platform"
                    object-type="campaign" />
                <selector
                    class="ad-set"
                    :platform="platform"
                    object-type="ad set" />
            </div>
        </div>
        <div class="existing-bottom">
            <div>
                <v-switch
                    v-model="adsSwitchValue"
                    class="switcher ml-2"
                    color="primary"
                    @change="toggleAdSwitch()" />
            </div>
            <div>
                Deploy with these ads enabled
            </div>
            <div>
                <icon-with-tooltip
                    class="single-icon"
                    has-tooltip
                    icon="question-circle"
                    size="18"
                    :tooltip="$t('adDeployment.adDestination.tooltip', { adCount, platformName })" />
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import Selector from '@/components/ad-deployment/steps/AdDestination/Selector';
import IconWithTooltip from '@/components/ad-studio/editing/preview-step/IconWithTooltip';
import { BB_STATUS_ACTIVE, BB_STATUS_PAUSED } from '@/helpers/globals.js';
import { DEPLOYMENT_PLATFORMS } from '@/components/ad-deployment/store/constants.js';

export default {
    components: {
        Icon,
        Selector,
        IconWithTooltip
    },
    props: {
        platform: {
            type: String,
            required: true
        },
    },
    computed: {
        adCount() {
            return this.$store.state.adDeployment.ads.filter(ad => ad.configuration.selected_platforms.includes(this.platform)).length;
        },
        adsSwitchValue: {
            get() {
                return this.switchValue();
            },
            set() {}
        },
        platformName() {
            return DEPLOYMENT_PLATFORMS.find(p => p.name === this.platform).ui_name;
        }
    },
    methods: {
        toggleAdSwitch() {
            this.$store.commit('adDeployment/updateStatusForSinglePlatformAndObjects', {
                value: this.adsSwitchValue ? BB_STATUS_PAUSED : BB_STATUS_ACTIVE,
                objects: ['ad'],
                platforms: [this.platform]
            });
        },
        switchValue() {
            return this.$store.state?.adDeployment?.statuses?.platform_specific?.[this.platform]?.ad?.status === BB_STATUS_ACTIVE;
        }
    }
};
</script>


<style lang="scss" scoped>
.single-existing{
    display: flex;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    padding-bottom: 10px;
}
.existing-container {
    margin-bottom: 15px;
    background: white;
    border: 1px solid #DFDFE0;
    border-radius: 5px;
}
.existing-bottom {
    border-top: 1px solid #DFDFE0;
    display: flex;
    align-items: center;
    padding: 0px 20px;
}
.existing-icon{
    margin-right: 30px;
    margin-top: 5px;
}
.styled-field{
    width: 100%;
}
.selector-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    & > .ad-set {
        width: 95%;
    }
}
</style>
<style lang="scss">
.single-existing .existing-field {
    .v-select__selections{
        overflow: hidden;
    }
}

.v-input--selection-controls {
    padding-top: 8px;
}
</style>