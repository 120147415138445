var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],staticClass:"existing-field container-object"},[_c('div',{staticClass:"container-autocomplete"},[_c('v-autocomplete',{staticClass:"styled-field",attrs:{"items":_vm.objectsToUse,"item-disabled":_vm.itemDisabled,"return-object":true,"loading":_vm.loading,"item-text":"name","item-value":"id","label":_vm.getLabel,"error-messages":_vm.errorMessage,"required":"","filter":_vm.filterObject,"search-input":_vm.search},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.handleObjectSelection},scopedSlots:_vm._u([{key:"item",fn:function({item}){return [(item.lazyLoading)?_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onNextPage),expression:"onNextPage"}]},[_vm._v(" ...Loading ")]):_c('div',[_c('div',[(item.validationErrors.length === 0)?_c('div',[_c('div',{staticClass:"option"},[_c('div',{staticClass:"status"},[_c('div',{class:{
                                            'available': item.status === _vm.activeStatus,
                                            'not-available': item.status === _vm.pausedStatus
                                        }})]),_c('div',{staticClass:"option-text"},[_vm._v(" "+_vm._s(item.name)+" ")])]),(item.info_messages.length > 0)?_c('div',{staticClass:"sub-text ad-set-error-message"},[_vm._v(" "+_vm._s(item.info_messages[0])+" ")]):_vm._e()]):_vm._e(),(item.validationErrors.length > 0)?_c('div',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('div',{staticClass:"option"},[_c('div',{staticClass:"status"},[_c('div',{class:{
                                            'available': item.status === _vm.activeStatus,
                                            'not-available': item.status === _vm.pausedStatus
                                        }})]),_c('div',{staticClass:"option-text-disabled"},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('div',{staticClass:"sub-text"},[(item.validationErrors.length > 0)?_c('div',{staticClass:"ad-set-error-message"},[_vm._v(" "+_vm._s(item.validationErrors[0].errorMessage)+" ")]):_vm._e()])]):_vm._e()])])]}},{key:"selection",fn:function({ item }){return [_c('div',{staticClass:"option"},[_c('div',{staticClass:"status"},[_c('div',{class:{
                                'available': item.status === _vm.activeStatus,
                                'not-available': item.status === _vm.pausedStatus
                            }})]),_c('div',[_vm._v(" "+_vm._s(_vm.trimSelection(item.name))+" ")])])]}}])})],1),_c('div',{staticClass:"switch-container"},[(_vm.isCampaignComponent)?_c('span',{staticClass:"enable-text"},[_vm._v("DISABLE/ENABLE")]):_vm._e(),_c('v-switch',{staticClass:"switcher ml-2",attrs:{"color":"primary","true-value":_vm.activeStatus,"false-value":_vm.pausedStatus,"disabled":_vm.disableSwitcher,"input-value":_vm.objectStatus},on:{"change":_vm.switchObject}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }